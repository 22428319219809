.pulse-oxymetry-container {
  min-height: 100px;
  /*margin: 30px 0 0;*/
  /*display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;*/
  border: 2px solid;
  border-color: #3f51b5;
  width: 100%;

  /*align-content: center;*/
}

/*.ecg-container .header-container {
  display: flex;
  width: 100%;
}*/

.pulse-oxymetry-container .header-text {
  width: 50%;
}
.pulse-oxymetry-container .cross {
  font-size: 35px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  margin: 3px;
  color: #1f5fc8;
  float: right;
}

.pulse-oxymetry-container .followup-text-block {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
}

.pulse-oxymetry-container .followup-text-block .description p {
  color: rgb(82,82,82);
  font-weight: 400;
}
.pulse-oxymetry-container .followup-text-block .value p {
  font-size: 20px;
  margin-left: 1rem;
  font-weight: 500;
  color: rgb(49,49,218);
}
.pulse-oxymetry-container .followup-text .pulse-oxymetry-pie-chart {
  width: 15%;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.pulse-oxymetry-pie-chart {
  /*width: 15%;*/
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.pulse-oxymetry-result-container{
  /*width:95%;*/
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 350px;
  margin-bottom: 1rem;
  /*padding-top: 75%;*/
}

.pulse-oxymetry-result-container .pulse-oxymetry-reference-image {
  width: 100%;
  background: url("../../assets/images/pulse_oxymetry_reference_values.png") no-repeat center center;
  -webkit-background-size: contain;
  background-size: contain;
  min-width: 100px;
  min-height: 170px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
}