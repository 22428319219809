.crp-container {
  min-height: 100px;
  /*margin: 30px 0 0;*/
  /*display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;*/
  border: 2px solid;
  border-color: #3f51b5;
  width: 100%;
  /*align-content: center;*/
}

.crp-container .header-text {
  width: 50%;

}

.crp-container .header-text p {
  font-size: 20px;
  color: #7092BF;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  color: rgb(49, 49, 218);;
}

.crp-container .cross {
  font-size: 35px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  margin: 3px;
  color: #1f5fc8;
  float: right;
}

.crp-container .followup-text-block {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
}

.crp-container .followup-text-block .description p {
  color: rgb(82,82,82);
  font-weight: 400;
}
.crp-container .followup-text-block .value p {
  font-size: 20px;
  margin-left: 1rem;
  font-weight: 500;
  color: rgb(49,49,218);
}

.crp-result-container{
  /*width:95%;*/
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 350px;
  margin-bottom: 1rem;
  /*padding-top: 75%;*/
}

.crp-result-container .crp-reference-image {
  width: 100%;
  background: url("../../assets/images/crp_reference_values.png") no-repeat center center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: -0.5rem;
  min-width: 100px;
  min-height: 120px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
}