.listener-test-lung .bravo-block {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 0;
  background-color: #e5e5e5;
  flex-wrap: wrap;
  height: 190px;
  /*margin-left: 1rem;*/
  margin-bottom: 1rem;
  /*margin-top: 20px;*/
  /*width: 70%;*/
}
.listener-test-lung .bravo-block .content {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  transform: translateY(-15px);
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.listener-test-lung .bravo-block .content .content-icon {
  width: 40%;
  margin: auto 0;
}
.listener-test-lung .bravo-block .content .content-icon:before {
  display: block;
  font-size: 40px;
  line-height: 67px;
  width: 70px;
  height: 70px;
  content: '';
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-family: FontAwesome;
  -webkit-text-stroke: 2px #e5e5e5;
}
.listener-test-lung .bravo-block .content .content-text {
  width: 60%;
}
.listener-test-lung .bravo-block .content .content-text:before {
  display: block;
  overflow: hidden;
  font-size: 16px;
  content: '';
  white-space: nowrap;
}
.listener-testv .bravo-block .content .content-text span.large:before {
  display: block;
  font-size: 30px;
  content: '';
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}
.listener-test-lung .bravo-block a.continue-button {
  display: block;
  text-decoration: none;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}
.listener-test-lung .bravo-block a.continue-button:link,
.listener-test-lung .bravo-block a.continue-button:visited {
  color: white;
}
.listener-test-lung .bravo-block a.continue-button:hover,
.listener-test-lung .bravo-block a.continue-button:focus,
.listener-test-lung .bravo-block a.continue-button:active {
  cursor: pointer;
  color: white;
}
.listener-test-lung .bravo-block.success .content {
  color: #00ff99;
}
.listener-test-lung .bravo-block.success .content .content-text {
  width: 30%;
  color: black;
}
.listener-test-lung .bravo-block.success .content .content-text:before {
  content: 'Correct ';
  
}
.listener-test-lung .bravo-block.success .content .content-text span.large:before {
  content: 'Bravo!';
}
.listener-test-lung .bravo-block.success .content .content-icon {
  width: 25%;
}
.listener-test-lung .bravo-block.success .content .content-icon:before {
  content: '\f00c';
  border: 4px solid #00ff99;
}
.listener-test-lung .bravo-block.success .content .content-message {
  width: 35%;
  margin: 10px 0 0 20px;
  color: black;
}
.listener-test-lung .bravo-block.success a.continue-button {
  background-color: #00ff99;
}
.listener-test-lung .bravo-block.success a.continue-button:link,
.listener-test-lung .bravo-block.success a.continue-button:visited {
  background-color: #00ff99;
}
.listener-test-lung .bravo-block.success a.continue-button:hover,
.listener-test-lung .bravo-block.success a.continue-button:focus,
.listener-test-lung .bravo-block.success a.continue-button:active {
  background-color: #33ffad;
}
.listener-test-lung .bravo-block.error .content {
  color: #ff0066;
}
.listener-test-lung .bravo-block.error .content .content-text {
  width: 30%;
  color: black;
}
.listener-test-lung .bravo-block.error .content .content-text:before {
  content: 'Wrong';
}
.listener-test-lung .bravo-block.error .content .content-text span.large:before {
  content: 'Sorry!';
}
.listener-test-lung .bravo-block.error .content .content-icon {
  width: 24%;
}
.listener-test-lung .bravo-block.error .content .content-icon:before {
  content: '\f00d';
  border: 4px solid #ff0066;
  line-height: 65px;
}
.listener-test-lung .bravo-block.error .content .content-message {
  width: 35%;
  margin: 10px 0 0 20px;
  color: black;
}
.listener-test-lung .bravo-block.error a.continue-button {
  background-color: #ff0066;
  text-decoration: none;
}
.listener-test-lung .bravo-block.error a.continue-button:link,
.listener-test-lung .bravo-block.error a.continue-button:visited {
  background-color: #ff0066;
}
.listener-test-lung .bravo-block.error a.continue-button:hover,
.listener-test-lung .bravo-block.error a.continue-button:focus,
.listener-test-lung .bravo-block.error a.continue-button:active {
  background-color: #ff3385;
}
.listener-test-lung .bravo-block.active {
  z-index: 20;
  opacity: 1;
}