.retake-block {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 20px;
  margin-top: 50px;
  /*width: 25%;*/
  height: 250px;
  margin-bottom: 20px;
  border: 2px solid #1f5fc8;
  /*cursor: pointer;*/
  flex-wrap: wrap;
  /*background-color:#7092BF;*/
  justify-content: center;
}

.retake-block .image-block {
  display: flex;
  position: relative;
  /*margin-top: 5px;*/
  /*background-color: #1f5fc8;*/
  width: 100%;
  height: 40%;
}
.retake-block .image-block .lung-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../../assets/images/retake_lung.png") no-repeat center center;
  -webkit-background-size: contain;
  background-size: contain;
}

.retake-block .image-block .heart-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../../assets/images/retake_heart.png") no-repeat center
    center;
  -webkit-background-size: contain;
  background-size: contain;
}

.retake-block .image-block .followup-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../../assets/images/retake_followup.png") no-repeat center
    center;
  -webkit-background-size: contain;
  background-size: contain;
}

.retake-block .success-icon {
  display: inline-flex;
  flex-direction: column;
  font-size: 75px;
  line-height: 15%;
  /*width: 40px;*/
  height: 40%;
  /*margin: 10px;*/
  justify-content: center;
  color: green;
}

.retake-block .failure-icon {
  display: inline-flex;
  flex-direction: column;
  font-size: 75px;
  line-height: 15%;
  /*width: 40px;*/
  height: 40%;
  /*margin: 10px;*/
  justify-content: center;
  color: red;
}

.retake-block .retake-button {
  display: flex;
  height: 20%;
  width: 100%;
  background-color: #1f5fc8;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.retake-block .retake-button .text {
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.followup-container .test-block .text-field {
  margin: auto;
}

.followup-container .test-block .text-field p {
  font-weight: 700;
  color: white;
  margin-bottom: 0em;
}
