.patient-container {
  overflow: hidden;
  /*margin: 15px 0;*/
  /*padding: 0 0 0 10px;*/
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /*border: 1px solid #efefef;*/
  overflow-y: auto;
  max-height: 450px;
}

.patient-container .patient-content {
  width:95%;
  position: relative;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 350px;
  padding-top: 75%;
}
.patient-container .patient-content .patient-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*background: url("/assets/images/male11-nobackground.jpg") no-repeat center center;*/
  -webkit-background-size: contain;
  background-size: contain;
}

.patient-container .patient-content .patient-image .case-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*background: url("/assets/images/male11-nobackground.jpg") no-repeat center center;*/
  -webkit-background-size: contain;
  background-size: contain;
}

.patient-info {
  /*display: inline-block;*/
  /*width: 100px;
  height: 100px;*/
  width: 100%;
  height: 100%;
  padding: 5px;
  border: 1px solid #5fc7c9;    
  /*background-color: yellow;*/
}

.patient-info ul {
  /*display: flex;*/
  list-style-type: none;
}

.patient-info ul li {
  padding: 5px;

}

.case-details-container {
  margin: 30px;
}

.patient-info ul li .marker{
  color: rgb(49, 49, 218);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10;
}

.visit-block {
  display: flex;
  padding-top: 25%;
  padding-left: 5%;
  justify-content: space-evenly;
  text-align: center;
}
.visit-block .visit-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  /*white-space:nowrap;*/
  padding-left: 15px;

}

.visit-block .visit-text .marker {
  color: rgb(49, 49, 218);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12; 
  display: contents;
}

.visit-block .search-icon {
  font-size: 44px;
  line-height: 22px;
  width: 44px;
  height: 44px;
  text-align: center;
}

.button-block {
  float: center;
  color: white;
  display: flex;
  margin-right: 8px;
}
.questionaire-details {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  height: 100%;
  padding-top: 25%;
}

.questionaire-details p {
  font-weight: 500;
  color: black;
}

.questionaire-icons {
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
.questionaire-line {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  widows: 100%;
}

.questionaire-line .icon {
  display: inline-block;
  font-size: 25px;
  /*line-height: 10px;*/
  width: 20px;
  height: 25px;
  margin: 3px;
  /*text-align: center;*/
  /*padding-left: 10px;*/
}

.case-button {
  padding-right: 20px;
}