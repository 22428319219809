.hemoglobin-result-container{
  /*width:95%;*/
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 350px;
  margin-bottom: 1rem;
  /*padding-top: 75%;*/
}

.hemoglobin-result-container .hemoglobin-reference-image {
  width: 100%;
  background: url("../../assets/images/hb_reference_values.png") no-repeat center center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: -0.5rem;
  min-width: 150px;
  min-height: 210px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
}