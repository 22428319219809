.finish-popup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px;
  height: 200px;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  /*cursor: pointer;*/
  flex-wrap: wrap;
  /*background-color:#7092BF;*/
  /*border-width: 5px;
  border-color: rgb(49, 49, 218);
  border-style: solid;*/
  background-color: white;
  /*justify-content: center;*/
  align-content: center;
  border: 0px;
}

.finish-popup .text-header {
  color: rgb(49, 49, 218);
  text-align: center;

}
.finish-popup .text-header p {
  font-weight: 600;
  font-size: 20px;
}

.finish-popup .button-block {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}
.finish-popup .button-block .button {
  margin: auto
}
/*.followup-container .test-block .text-field {
margin: auto;
}

.followup-container .test-block .text-field p {
font-weight: 700;
color: white;
margin-bottom: 0em;
}

.followup-container {
min-height: 100px;
margin: 30px 0 0;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
}

.followup-header {
text-align: center;
}
*/

