.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .remaining-duration {
  display: block;
  position: absolute;
  right: 0;
  height: 6px;
  background-color: white;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .remaining-duration:before {
  display: block; 
  position: absolute;
  top: -5px;
  left: -6px;
  width: 12px;
  height: 12px;
  content: '';
  border: 2px solid white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #dde6f6;
}

.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .needle {
  display: block;
  position: absolute;
  z-index: 30;
  top: 0;
  left: 3.616%;
  left: 0;
  width: 3px;
  /*height: 240px;*/
  height: 300px;
  opacity: 0.6;
  background-color: black;
}