.followup-container .test-block {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 20px;
    width: 20%;
    height: 160px;
    margin-bottom: 20px;
    border: 1px solid #dfdfdf;
    /*cursor: pointer;*/
    flex-wrap: wrap;
    background-color:#7092BF;
    cursor: pointer;
}
  
.followup-container .test-block .text-field {
  margin: auto;
}

.followup-container .test-block .text-field p {
  font-weight: 700;
  color: white;
  margin-bottom: 0em;
  cursor: pointer;
}

.followup-container {
  min-height: 100px;
  /*margin: 30px 0 0;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.followup-container .button-block{
 justify-content: center;
 width: 100%;
}

.followup-header {
  text-align: center;
  width: 100%;
}


.choosen-text {
  width: 100%;
  display: flex;
  justify-content: center;
  color: red;
}


