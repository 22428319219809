.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper {
  height: 60px;
  text-align: center;
  border-bottom: 5px solid #dde6f6;
  background-color: #f6f9ff;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block {
  width: 33.3%;
  background: url("../assets/images/warning2.svg") no-repeat 4% center;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items {
  margin-left: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation {
  padding: 5px 0;
  text-align: left;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation:before {
  font-size: 16px;
  padding-right: 10px;
  content: '\f111';
  font-family: FontAwesome;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation.inspiration:before {
  color: #2edfbb;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation.expiration:before {
  color: #fcd3c9;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar {
  display: inline-block;
  width: 33.3%;
  margin: 0 auto;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function {
  display: inline-block;
  font-size: 25px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  margin: 10px;
  text-align: center;
  float: left;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function:link,
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function:visited {
  color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function:hover,
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function:focus,
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function:active {
  color: #3a79e0;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar {
  width: 33.3%;
  text-align: right;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul {
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 0;
  cursor: pointer;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul li {
  display: inline-block;
  list-style: none;
  vertical-align: bottom;
  pointer-events: none;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul li .bar {
  display: block;
  width: 5px;
  height: 10px;
  margin: 0 1.5px;
  pointer-events: none;
  background-color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul li:nth-child(2) .bar {
  height: 15px;
  opacity: 1;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul li:nth-child(3) .bar {
  height: 20px;
  opacity: 1;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul li:nth-child(4) .bar {
  height: 25px;
  opacity: 1;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul.vol-75 li:nth-child(4) .bar {
  opacity: 0.2;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul.vol-50 li:nth-child(4) .bar {
  opacity: 0.2;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul.vol-50 li:nth-child(3) a {
  opacity: 0.2;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul.vol-25 li:nth-child(4) .bar {
  opacity: 0.2;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul.vol-25 li:nth-child(3) .bar {
  opacity: 0.2;
}
.analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul.vol-25 li:nth-child(2) .bar {
  opacity: 0.2;
}

.analyze-page-wrapper.test-block .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block {
  opacity: 100;
}

@media only screen and (max-width: 1200px) {
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items {
    margin-left: 50px;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block {
    width: 33.3%;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items {
    flex-direction: column;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation {
    line-height: 15px;
    padding: 0;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar {
    width: 33.3%;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function {
    margin: 5px;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar {
    width: 33.3%;
  }
}

@media only screen and (max-width: 479px) {
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block {
    width: 33.3%;
    background-image: none;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items {
    margin-left: 0;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation {
    font-size: 10px;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .explanation-block .explanation-items .explanation:before {
    font-size: 10px;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar {
    width: 33.3%;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .audio-functionality-toolbar a.audio-function {
    font-size: 15px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    margin: 3px;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul {
    padding: 0 10px 0 0;
  }
  .analyze-page-wrapper .analyze-page-main-content .audio-functionality-toolbar-wrapper .volume-functionality-toolbar ul li a {
    margin: 0 0.5px;
  }
}