.followup-container {
  min-height: 100px;
  margin: 30px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.followup-header {
  text-align: center;
}


.case-result-button {
  padding-left: 20px;
  width: 100%
}