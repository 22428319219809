.auscultation-content-wrapper .content-choser {
  /*margin-top: -40px;*/
  padding: 30px 20px;
  text-transform: uppercase;
  border: 1px solid #efefef;
}
.auscultation-content-wrapper .content-choser ul {
  display: table;
  position: relative;
  margin: 0;
  padding: 0;
}
.auscultation-content-wrapper .content-choser ul:before {
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  width: 20px;
  height: 20px;
  content: '';
  background-color: white;
}
.auscultation-content-wrapper .content-choser ul:after {
  display: block;
  position: absolute;
  bottom: -6px;
  left: -4px;
  width: 20px;
  height: 20px;
  content: '';
  background-color: white;
}
.auscultation-content-wrapper .content-choser ul li {
  display: table-row;
  list-style: none;
}
.auscultation-content-wrapper .content-choser ul li:before {
  display: table-cell;
  width: 14px;
  height: 42px;
  content: '';
  vertical-align: middle;
  background-image: url("../assets/images/programme-choser-bullet.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.auscultation-content-wrapper .content-choser ul li a {
  display: table-cell;
  line-height: 1;
  padding-left: 10px;
  vertical-align: middle;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
}
.auscultation-content-wrapper .content-choser ul li a:link,
.auscultation-content-wrapper .content-choser ul li a:visited {
  color: #1f5fc8;
}
.auscultation-content-wrapper .content-choser ul li a:hover,
.auscultation-content-wrapper .content-choser ul li a:focus,
.auscultation-content-wrapper .content-choser ul li a:active {
  color: #3a79e0;
}
.auscultation-content-wrapper .content-choser ul li.active:before {
  background-image: url("../assets/images/programme-choser-bullet-active.svg");
}
.auscultation-content-wrapper .content-choser ul li.active a {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}