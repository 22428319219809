.intro-header-block {
  margin-bottom: 2rem;
}

.content-block p {
  color: rgb(82, 82, 82);
}
.intro-image-container {
  /*width:95%;*/
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 550px;
  margin-bottom: 1rem;
  /*padding-top: 75%;*/
}
.spir-puls-image-container {
  /*width:95%;*/
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 350px;
  margin-bottom: 1rem;
  height: 200px;
  /*padding-top: 75%;*/
}

/*.intro-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../assets/images/team_image.png") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
  height: 500px;
}*/

.intro-image {
  width: 100%; /* or a specific pixel value */
  min-height: 500px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/team_image.png") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}

/*.sound-recording-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../assets/images/recording-equipment") no-repeat center center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}*/

.image-container {
  overflow: hidden;
  height: 350px; /* Temporary fixed height for testing */
  margin-bottom: 1rem;
}

.sound-recording-image {
  width: 100%; /* or a specific pixel value */
  min-height: 300px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/recording-equipment") no-repeat center
    center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}

.auscultation-image {
  /*position: absolute;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 300px;
  background: url("../assets/images/auscultation_sites_intro.png") no-repeat
    center top;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
  margin-top: -0.5 rem;
}

.lung-auscultation-front-image {
  /*position: absolute;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 150px;
  background: url("../assets/images/lung_auscultation_front.png") no-repeat
    center top;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
  margin-top: -0.5 rem;
}

.lung-auscultation-back-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 150px;
  background: url("../assets/images/lung_auscultation_back.png") no-repeat
    center center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
  margin-top: 2rem;
}

.heart-auscultation-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 150px;
  background: url("../assets/images/heart_auscultation.png") no-repeat center
    center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
  margin-top: 2rem;
}

.auscultation-sites {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.symptoms-image-container {
  /*width:95%;*/
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  max-height: 350px;
  min-height: 315px;
  margin-bottom: 1rem;
  height: 315px;
  /*padding-top: 75%;*/
}

.symptoms-image {
  width: 100%; /* or a specific pixel value */
  min-height: 300px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/dyspnea c.png") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}

.oximetry-image {
  width: 100%; /* or a specific pixel value */
  min-height: 300px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/oximetry.jpg") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}

.spirometry-image {
  width: 100%; /* or a specific pixel value */
  min-height: 300px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/spirometry.png") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}

.ecgecho-image {
  width: 100%; /* or a specific pixel value */
  min-height: 400px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/ecgecho.jpg") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}

/*.ecgecho-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../assets/images/ecgecho.jpg") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
  height: 400px;
}*/

.bloodtests-image {
  width: 100%; /* or a specific pixel value */
  min-height: 300px; /* Adjust as needed */
  height: auto; /* maintain aspect ratio */
  background: url("../assets/images/bloodtests.png") no-repeat top center;
  -webkit-background-size: contain;
  background-size: contain;
  margin-left: 1rem;
}
