.spirometry-container {
    min-height: 100px;
    width: 100%;
    /*margin: 30px 0 0;*/
    /*display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;*/
    border: 2px solid;
    border-color: #3f51b5;
    /*align-content: center;*/
  }
  
  /*.spirometry-container .header-container {
    display: flex;
    width: 100%;
  }*/
  
  
  .result-description p {
    color: rgb(82,82,82);
  }
  
  .spirometry-container .cross {
    font-size: 35px;
    line-height: 35px;
    width: 35px;
    height: 35px;
    margin: 3px;
    color: #1f5fc8;
    float: right;
  }
  .spirometry-container .image-container {
    display: flex;
  }
  .spirometry-container .image {
    max-width: 80vw;
    max-height: 50vh;
    height:auto;
  
  }
  
  /*.analyze-page-wrapper {
    max-width: 835px;
  }
  
  .analyze-page-wrapper .analyze-page-data-block {
    padding: 25px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }*/
  
  .listener-test {
    position: relative;
    width: 49%;
    margin-left: 1rem;
  }
  .listener-test form.test-form .form-line {
    display: inline-block;
    margin-bottom: 20px;
  }
  .listener-test form.test-form .form-line label {
    font-weight: 400;
    margin-right: 20px;
  }
  .listener-test form.test-form .form-line .question {
    display: block;
    margin-bottom: 10px;
    color: #1f5fc8;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
  }
  .listener-test form.test-form .form-line.full {
    width: 100%;
  }
  .listener-test form.test-form .form-line.half {
    width: 49%;
  }
  .listener-test form.test-form input[type=submit] {
    display: block;
    width: 100%;
    padding: 10px 0;
    color: white;
    border: 0;
    background-color: #1f5fc8;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
  }
  
  label {
    font-weight: 400;
    margin-right: 20px;
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -2px 10px 0 0;
    cursor: pointer;
    vertical-align: middle;
    background: url("../../assets/images/check_radio_sheet.png") -41px top no-repeat;
  }
  input[type='radio']:checked + label span {
    background: url("../../assets/images/check_radio_sheet.png") -60px top no-repeat;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox'] + label span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -2px 10px 0 0;
    cursor: pointer;
    vertical-align: middle;
    background: url("../../assets/images/check_radio_sheet.png") 1px top no-repeat;
  }
  input[type='checkbox']:checked + label span {
    background: url("../../assets/images/check_radio_sheet.png") -20px top no-repeat;
  }
  
  .bravo-block-tets {
    margin-left: 1rem;
  }