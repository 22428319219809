.analyze-page-wrapper {
  max-width: 835px;
  margin-bottom: 15px;
  /*margin-left: 15px;*/
}

.analyze-page-wrapper.analyze-block .listener-test {
  display: none;
}

.analyze-page-wrapper .analyze-page-main-content {
  /*margin-top: -35px;*/
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 5px 0 0;
  background-repeat: no-repeat;
  background-position: center center;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.right > .tooltip-inner {
  background-color: #1f5fc8;
  color: white;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.right > .tooltip-arrow {
  border-right-color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.bottom > .tooltip-inner {
  background-color: #1f5fc8;
  color: white;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.bottom > .tooltip-arrow {
  border-bottom-color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.left > .tooltip-inner {
  background-color: #1f5fc8;
  color: white;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.left > .tooltip-arrow {
  border-left-color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.top > .tooltip-inner {
  background-color: #1f5fc8;
  color: white;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.top > .tooltip-arrow {
  border-top-color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser:link,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser:visited {
  background-color: #dedede;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser:hover,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser:focus,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser:active {
  background-color: #c5c5c5;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.right > .tooltip-inner,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser + .tooltip.top > .tooltip-inner {
  padding: 10px;
  text-transform: uppercase;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.active:link,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.active:visited {
  background-color: #1f5fc8;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.active:hover,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.active:focus,
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.active:active {
  background-color: #3a79e0;
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.spectral {
  background-image: url("../assets/images/audio-choser-spectral.svg");
}
.analyze-page-wrapper .analyze-page-main-content .audio-display-toolbar a.audio-choser.waveform {
  background-image: url("../assets/images/audio-choser-waveform.svg");
}

.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper {
  overflow: hidden;
  position: relative;
  max-height: 0;
  margin: 0;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .frame {
  display: block;
  position: absolute;
  z-index: 900;
  top: 0;
  height: 240px;
  pointer-events: none;
  border: 2px solid;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .frame:before {
  font-family: 'Ubuntu', sans-serif;
  font-size: 9px;
  font-weight: 700;
  line-height: 15px;
  position: absolute;
  left: 0;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .frame.wheese {
  border-color: #ff8080;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .frame.wheese:before {
  content: 'Wheeze';
  background-color: #ff8080;
  top: 30px;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .frame.crackle {
  border-color: #73264d;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .frame.crackle:before {
  content: 'Crackle';
  background-color: #73264d;
  top: 50px;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  margin-left: 30px;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .phases {
  display: block;
  position: absolute;
  z-index: 32;
  top: 264px;
  left: 0;
  height: 5px;
  background-color: white;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .phases .phase {
  display: inline-block;
  position: absolute;
  height: 5px;
  background-color: yellow;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .phases .phase.inspiration {
  background-color: #2edfbb;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .phases .phase.expiration {
  background-color: #fcd3c9;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .remaining-duration {
  display: block;
  position: absolute;
  right: 0;
  height: 6px;
  background-color: white;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper .remaining-duration:before {
  display: block;
  position: absolute;
  top: -5px;
  left: -6px;
  width: 12px;
  height: 12px;
  content: '';
  border: 2px solid white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #dde6f6;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper .analyze-frame-wrapper.short {
  overflow: hidden;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper canvas.analyze {
  position: relative;
  top: 0;
  left: 0;
  /*height: 270px;*/
  height: 300px;
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper canvas.greed-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 835px;
  height: 310px;
  /*height: 280px;*/
}
.analyze-page-wrapper .analyze-page-main-content .canvas-wrapper.active {
  /*height: 280px;*/
  height: 310px;
  max-height: 330px;
  /*max-height: 300px;*/
}