
.listener-test-lung {
  position: relative;
  /*margin-left: 1rem;*/
  width: 100%;
  /*padding-bottom: 20px;*/
}
.listener-test-lung form.test-form .form-line {
  display: inline-block;
  margin-bottom: 20px;
}
.listener-test-lung form.test-form .form-line label {
  font-weight: 400;
  margin-right: 20px;
}
.listener-test-lung form.test-form .form-line .question {
  display: block;
  margin-bottom: 10px;
  color: #1f5fc8;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}
.listener-test-lung form.test-form .form-line.full {
  width: 100%;
}
.listener-test-lung form.test-form .form-line.half {
  width: 49%;
}
.listener-test-lung form.test-form input[type=submit] {
  display: block;
  width: 100%;
  padding: 10px 0;
  color: white;
  border: 0;
  background-color: #1f5fc8;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}

label {
  font-weight: 400;
  margin-right: 20px;
}
input[type='radio'] {
  display: none;
}
input[type='radio'] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -2px 10px 0 0;
  cursor: pointer;
  vertical-align: middle;
  background: url("../../assets/images/check_radio_sheet.png") -41px top no-repeat;
}
input[type='radio']:checked + label span {
  background: url("../../assets/images/check_radio_sheet.png") -60px top no-repeat;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -2px 10px 0 0;
  cursor: pointer;
  vertical-align: middle;
  background: url("../../assets/images/check_radio_sheet.png") 1px top no-repeat;
}
input[type='checkbox']:checked + label span {
  background: url("../../assets/images/check_radio_sheet.png") -20px top no-repeat;
}