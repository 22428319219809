*:focus {
  outline: none;
}
* {
  margin: 0;
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}

* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
 }
*:before,
*:after {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
 }

h1 {
  font-size: 45px;
  color: #1f5fc8;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
}

@media (min-width: 768px) {
 .container {
  width:750px
 }
}
@media (min-width: 992px) {
 .container {
  width:970px
 }
}
@media (min-width: 1200px) {
 .container {
  width:1170px;
 }
}

@media (min-width: 1400px) {
 .container {
  width:1470px;
 }
}

.container-fluid {
  margin-right:auto;
  margin-left:auto;
  padding-left:15px;
  padding-right:15px
}
.container-fluid:before,
.container-fluid:after {
  content:" ";
  display:table
}
.container-fluid:after {
  clear:both
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}

html,
body {
   font-size: 15px;
   color: #525252;
   line-height: 160%;
   font-family: 'Ubuntu', sans-serif;
   font-weight: 400;
 }
a {
  outline: none;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

h1.center {
  text-align: center;
}

.container {
  margin-right:auto;
  margin-left:auto;
  padding-left:15px;
  padding-right:15px
}

 .container:before,
.container:after {
  content:" ";
  display:table
}
.container:after {
  clear:both
}

.header-wrapper {
  background: url('./assets/images/header-inner-background.svg') center bottom repeat;
  background: url('./assets/images/header-inner-background.svg') center bottom repeat, -moz-linear-gradient(top, #1f5fc8 0%, #1f5fc8 70%, #ffffff 70%, #ffffff 100%);
  background: url('./assets/images/header-inner-background.svg') center bottom repeat, -webkit-linear-gradient(top, #1f5fc8 0%, #1f5fc8 70%, #ffffff 70%, #ffffff 100%);
  background: asset-data-url('./assets/images/header-inner-background.svg') center bottom repeat, linear-gradient(to bottom, #1f5fc8 0%, #1f5fc8 70%, #ffffff 70%, #ffffff 100%);
  background-color: #444444;
  -webkit-background-size: cover;
  background-size: cover;
}

.header-wrapper .header-inner {
  height: 95px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header-wrapper .header-inner .logo-container {
  width: 30%;
}
.header-wrapper .header-inner .main-menu {
  width: 50%;
}
.header-wrapper .header-inner .user-block.logedin {
  width: 20%;
}

.logo-container {
  display: inline-block;
  width: calc(33.33333333%);
  vertical-align: middle;
}
  
.logo-container a {
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}
.logo-container a:link,
.logo-container a:visited {
  color: white;
}
.logo-container a:hover,
.logo-container a:focus,
.logo-container a:active {
  color: #e6e6e6;
}

.main-content {
  min-height: calc(100vh - 225px);
  padding: 15px 0;
  height: 50%;
}

.main-content h3 {
  color: rgb(49, 49, 218);
}

.main-content p {
  color: rgb(79, 79, 182);
}

.step-label {
  color: 'red';
}

.header-one-markup {
  color: rgb(49, 49, 218);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12; 
  display: contents;
}



.header-text p {
  font-size: 20px;
  color: #7092BF;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  color: rgb(49, 49, 218);;
}

.content-container {
  margin: 30px;
}