*:focus {
  outline: none;
}
* {
  margin: 0;
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}

* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
 }
*:before,
*:after {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
 }


.container-fluid {
  margin-right:auto;
  margin-left:auto;
  padding-left:15px;
  padding-right:15px
}
.container-fluid:before,
.container-fluid:after {
  content:" ";
  display:table
}
.container-fluid:after {
  clear:both
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}

html,
body {
   font-size: 15px;
   color: #525252;
   line-height: 160%;
   font-family: 'Ubuntu', sans-serif;
   font-weight: 400;
 }
a {
  outline: none;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

.container {
    margin-right:auto;
    margin-left:auto;
    padding-left:15px;
    padding-right:15px
  }
  
   .container:before,
  .container:after {
    content:" ";
    display:table
  }
  .container:after {
    clear:both
  }
  
  .header-wrapper {
    background: url('../../assets/images/header-inner-background.svg') center bottom repeat;
    background: url('../../assets/images/header-inner-background.svg') center bottom repeat, -moz-linear-gradient(top, #1f5fc8 0%, #1f5fc8 70%, #ffffff 70%, #ffffff 100%);
    background: url('../../assets/images/header-inner-background.svg') center bottom repeat, -webkit-linear-gradient(top, #1f5fc8 0%, #1f5fc8 70%, #ffffff 70%, #ffffff 100%);
    background: asset-data-url('../../assets/images/header-inner-background.svg') center bottom repeat, linear-gradient(to bottom, #1f5fc8 0%, #1f5fc8 70%, #ffffff 70%, #ffffff 100%);
    /*background-color: #444444;*/
    -webkit-background-size: cover;
    background-size: cover;
  }
  
  .header-wrapper .header-inner {
    height: 95px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .header-wrapper .header-inner .logo-container {
    display: flex;
    width: 70%;
  }
  .header-wrapper .header-inner .logo-container .t7-logo {
    width: 70%;
    height: 100px;
    background: url('../../assets/images/TU7_Logo_Engelsk_Liggende.png') center no-repeat;
    background-size: 50%;
  }

  .header-wrapper .header-inner .logo-container .header-link {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-wrapper .header-inner .main-menu {
    width: 30%;
  }
  .header-wrapper .header-inner .user-block.logedin {
    width: 20%;
    z-index: 1;
  }
  
  .logo-container {
    display: inline-block;
    width: calc(33.33333333%);
    vertical-align: middle;
  }
    
  .logo-container a {
    font-size: 13px;
    text-transform: uppercase;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
  }
  .logo-container a:link,
  .logo-container a:visited {
    color: white;
  }
  .logo-container a:hover,
  .logo-container a:focus,
  .logo-container a:active {
    color: #e6e6e6;
  }
  
  .main-menu {
    display: inline-block;
    width: 50%;
    padding: 0 5px;
    vertical-align: middle;
  }
  .main-menu ul {
    margin: 0;
    padding: 0;
    text-align: right;
  }
  .main-menu ul li {
    display: inline-block;
    list-style: none;
  }
  .main-menu ul li a {
    display: block;
    font-size: 12px;
    margin: 0 15px;
    padding: 0 0 3px 0;
  }
  .main-menu ul li a:link,
  .main-menu ul li a:visited {
    color: white;
  }
  .main-menu ul li a:hover,
  .main-menu ul li a:focus,
  .main-menu ul li a:active {
    color: #e6e6e6;
  }
  .main-menu ul li.active a {
    font-weight: 700;
    border-bottom: 2px solid #1b54b2;
  }
  
  .user-block {
    display: inline-block;
    width: calc(10.33333333%);
    text-align: right;
    vertical-align: middle;
  }
  .user-block a.login-button {
    font-size: 12px;
    padding: 6.5px 16px 7.5px;
    border: 2px solid white;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
  }
  .user-block a.login-button:link,
  .user-block a.login-button:visited {
    color: white;
  }
  .user-block a.login-button:hover,
  .user-block a.login-button:focus,
  .user-block a.login-button:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .user-block .user-menu {
    display: none;
  }
  .user-block .user-menu a {
    height: 44px;
    text-align: left;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .user-block .user-menu a:link,
  .user-block .user-menu a:visited {
    color: white;
  }
  .user-block .user-menu a .welcome {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
  .user-block .user-menu a .avatar {
    overflow: hidden;
    width: 44px;
    border: 2px solid white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  .user-block .user-menu a .avatar img {
    border: 1px solid #1f5fc8;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #ffffff;
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  .user-block .user-menu a .arrow {
    font-size: 22px;
    line-height: 22px;
    width: 22px;
    height: 22px;
    text-align: center;
  }
  .user-block .user-menu .dropdown-menu {
    padding: 0;
  }
  .user-block .user-menu .dropdown-menu > li a {
    display: block;
    font-size: 15px;
    line-height: 2.5;
    height: auto;
  }
  .user-block .user-menu .dropdown-menu > li a:link,
  .user-block .user-menu .dropdown-menu > li a:visited {
    color: #1f5fc8;
  }
  .user-block .user-menu .dropdown-menu > li a:hover,
  .user-block .user-menu .dropdown-menu > li a:focus,
  .user-block .user-menu .dropdown-menu > li a:active {
    color: #1f5fc8;
    background-color: #dfdfdf;
  }
  .user-block .user-menu .dropdown-menu > li a i {
    display: inline-block;
    vertical-align: middle;
  }
  .user-block.logedin {
    width: 15%;
    padding: 0 0 0 30px;
    text-align: center;
    color: white;
    border-left: 1px solid white;
  }
  .user-block.logedin a.login-button {
    display: none;
  }
  .user-block.logedin .user-menu {
    display: block;
  }
  
  .user-block a.login-button {
    font-size: 12px;
    padding: 6.5px 16px 7.5px;
    border: 2px solid white;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
  }
  .user-block a.login-button:link,
  .user-block a.login-button:visited {
    color: white;
  }
  .user-block a.login-button:hover,
  .user-block a.login-button:focus,
  .user-block a.login-button:active {
    background-color: rgba(255, 255, 255, 0.1);
  }