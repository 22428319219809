.main-content .profile-badges-container {
    min-height: 100px;
    margin: 30px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.main-content .profile-badges-container {
  min-height: 100px;
  margin: 30px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-content .profile-badges-container .profile-badge {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 48%;
  height: 160px;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  /*cursor: pointer;*/
  flex-wrap: wrap;
}

.main-content .profile-badges-container .profile-badge .badge-flag {
  display: none;
  position: absolute;
  /**
  ** how to use same-widt-height
  **   if unit given in px
  **   .same-width-height(65);
  **        will produce:
  **           width:65px;
  **           height:65px;
  **   other units need to be explicitly provided as an:
  **   .same-width-height(65,vw)
  **       will produce:
  **         width:65vw;
  **         height:65vw
  **/
  width: 99px;
  height: 99px;
  top: 0;
  left: 0;
}

.main-content .profile-badges-container .profile-badge .icon-block {
  display: flex;
  width: 30%;
}

.main-content .profile-badges-container .profile-badge .icon-block .icon-inner {
  overflow: hidden;
  margin: auto;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /**
  ** how to use same-widt-height
  **   if unit given in px
  **   .same-width-height(65);
  **        will produce:
  **           width:65px;
  **           height:65px;
  **   other units need to be explicitly provided as an:
  **   .same-width-height(65,vw)
  **       will produce:
  **         width:65vw;
  **         height:65vw
  **/
  width: 60px;
  height: 60px;
}
.main-content .profile-badges-container .profile-badge .icon-block .icon-inner:after {
  display: block;
  content: '';
  background-image: url("../../assets/images/waveform-icon-gray.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  background-size: contain;
  /**
  ** how to use same-widt-height
  **   if unit given in px
  **   .same-width-height(65);
  **        will produce:
  **           width:65px;
  **           height:65px;
  **   other units need to be explicitly provided as an:
  **   .same-width-height(65,vw)
  **       will produce:
  **         width:65vw;
  **         height:65vw
  **/
  width: 60px;
  height: 60px;
}
.main-content .profile-badges-container .profile-badge .text-content {
  display: flex;
  position: relative;
  width: 70%;
}
.main-content .profile-badges-container .profile-badge .text-content .text-inner {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}
.main-content .profile-badges-container .profile-badge .text-content .text-inner h3 {
  font-size: 15px;
  font-weight: 900;
  margin: 0 0 10px 0;
  color: #635858;
  font-family: 'Ubuntu', sans-serif;
  opacity: 0.95;
  /*font-weight: 400;*/
}
.main-content .profile-badges-container .profile-badge .text-content .text-inner .explanataion-block {
  font-size: 15px;
  line-height: 1.2;
  color: #525252;
}
.main-content .profile-badges-container .profile-badge .text-content .large-percentage-block {
  display: none;
  font-size: 62px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 10px;
  text-align: center;
  color: #eff2fa;
  z-index: -1;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
}
.main-content .profile-badges-container .profile-badge .profile-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
  background-color: #f4f4f4;
}
.main-content .profile-badges-container .profile-badge .profile-progress .real-profile-progress {
  width: 40px;
  min-width: 20px;
  height: 12px;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -ms-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
  background-color: #1f5fc8;
}
.main-content .profile-badges-container .profile-badge .submit-button-container {
  display: none;
  position: absolute;
  background-color: #1f5fc8;
  color: white;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
}
.main-content .profile-badges-container .profile-badge .submit-button-container a {
  line-height: 30px;
}
.main-content .profile-badges-container .profile-badge .submit-button-container a:link,
.main-content .profile-badges-container .profile-badge .submit-button-container a:visited {
  color: white;
}
.main-content .profile-badges-container .profile-badge .submit-button-container a:hover,
.main-content .profile-badges-container .profile-badge .submit-button-container a:focus,
.main-content .profile-badges-container .profile-badge .submit-button-container a:active {
  color: #e6e6e6;
}
.main-content .profile-badges-container .profile-badge.info .icon-block .icon-inner {
  background-color: #f4f4f4;
}
.main-content .profile-badges-container .profile-badge.info .icon-block .icon-inner:after {
  background-image: url("../../assets/images/waveform-icon-blue.svg");
}
.main-content .profile-badges-container .profile-badge.info:hover .icon-block .icon-inner {
  background-color: #1f5fc8;
}
.main-content .profile-badges-container .profile-badge.info:hover .icon-block .icon-inner:after {
  background-image: url("../../assets/images/waveform-icon-white.svg");
}
.main-content .profile-badges-container .profile-badge.info:hover .text-content .large-percentage-block {
  display: block;
}
.main-content .profile-badges-container .profile-badge.success .icon-block .icon-inner {
  background-color: #ffffff;
}
.main-content .profile-badges-container .profile-badge.success .icon-block .icon-inner:after {
  background-image: url("../../assets/images/check-icon-green.svg");
}
.main-content .profile-badges-container .profile-badge.success .profile-progress .real-profile-progress {
  width: 100%;
  background-color: #67d31f;
}

.main-content .profile-badges-container .profile-badge.error .icon-block .icon-inner {
  background-color: #ffffff;
}
.main-content .profile-badges-container .profile-badge.error .icon-block .icon-inner:after {
  background-image: url("../../assets/images/close.png");
}
.main-content .profile-badges-container .profile-badge.error .profile-progress .real-profile-progress {
  width: 100%;
  background-color: #ff0066;
}

.main-content .profile-badges-container .profile-badge.started .icon-block .icon-inner {
  background-color: #f4f4f4;
}
.main-content .profile-badges-container .profile-badge.started .icon-block .icon-inner:after {
  background-image: url("../../assets/images/waveform-icon-blue.svg");
}
.main-content .profile-badges-container .profile-badge.waiting .icon-block .icon-inner {
  background-color: #f4f4f4;
}
.main-content .profile-badges-container .profile-badge.waiting .profile-progress .real-profile-progress {
  background-color: transparent;
}
.main-content .profile-badges-container .profile-badge.waiting.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.6;
}
.main-content .profile-badges-container .profile-badge.upgrade.hover {
  cursor: pointer;
  opacity: 1;
}
.main-content .profile-badges-container .profile-badge.upgrade.hover .badge-flag {
  display: block;
  z-index: -1;
  background: url("../../assets/images/upgrade-normal.png") no-repeat -5px -5px;
}
.main-content .profile-badges-container .profile-badge.upgrade.hover .text-content .text-inner {
  transform: translateY(-14px);
}
.main-content .profile-badges-container .profile-badge.upgrade.hover .submit-button-container {
  display: block;
}
.main-content .profile-badges-container .profile-badge.upgrade {
  /*cursor: default;*/
  opacity: 0.6;
}
.main-content .profile-badges-container .profile-badge.upgrade .badge-flag {
  display: block;
  z-index: -1;
  background: asset-data-url("../../assets/images/upgrade-disabled.png") no-repeat -5px -5px;
}
.main-content .profile-badges-container .profile-badge.upgrade:hover {
  opacity: 1;
}
.main-content .profile-badges-container .profile-badge.upgrade:hover .badge-flag {
  display: block;
  z-index: -1;
  background-image: asset-data-url("../../assets/images/upgrade-normal.png");
  background-repeat: no-repeat;
  background-position: -5px -5px;
}
.main-content .profile-badges-container .profile-badge.upgrade:hover .submit-button-container {
  display: block;
}