.main-content .accordion-container {
  overflow: hidden;
  /*margin: 15px 0;*/
  /*padding: 0 0 0 10px;*/
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /*border: 1px solid #efefef;*/
  overflow-y: auto;
  max-height: 450px;
}

.main-content .accordion-container .accordion-item {
  width: 95%;
}

.analyze-page-data-block {
  padding: 25px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.test-container {
  margin-left: 1rem;
}

.main-content .accordion-container .accordion-item .accordion-trigger-block {
  margin: 0;
  -webkit-transition: background-color 1s;
  -moz-transition: background-color 1s;
  -ms-transition: background-color 1s;
  -o-transition: background-color 1s;
  transition: background-color 1s;
  /*border: 1px solid #efefef;*/
  border-right: 0;
  border-left: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #1f5fc8;
}
.main-content
  .accordion-container
  .accordion-item
  .accordion-trigger-block
  .title {
  width: 80%;
  color: white;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  padding: 10px;
}

.main-content .accordion-container .accordion-item .accordion-content {
  max-height: 350px;
  border: 1px solid #efefef;
  padding-top: 75%;
  position: relative;
}

.main-content
  .accordion-container
  .accordion-item
  .accordion-content
  .inner-content.back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*background: url("../../assets/images/lungs_1.png") no-repeat center center;*/
  -webkit-background-size: contain;
  background-size: contain;
}

.main-content
  .accordion-container
  .accordion-item
  .accordion-content
  .heart-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../../assets/images/heart_1.png") no-repeat center center;
  -webkit-background-size: contain;
  background-size: contain;
}

.main-content
  .accordion-container
  .accordion-item
  .accordion-content
  .inner-content.front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: asset-data-url("../../assets/images/lung-front.svg") no-repeat
    center center;
  -webkit-background-size: contain;
  background-size: contain;
}

.button-block {
  float: center;
}
